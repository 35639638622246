<template>
  <v-card>
    <v-card-title>
      Editar foto de perfil
    </v-card-title>

    <v-card-text>
      <v-file-input
        :label="$t('fields.picture')"
        solo
        outlined
        flat
        class="rounded-lg"
        v-model="profile.pictureAttributes.image"
        accept="image/*"
        hide-details
        :rules="[ v => !!v || 'El campo es obligatorio']">
      </v-file-input>

      <div
        class="text-right mt-3"
        >
        <v-btn
          color="primary"
          depressed
          :disabled="loading || !profile.pictureAttributes.image"
          :loading="loading"
          @click="edit"
          >
          Guardar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditProfile } from '@/graphql/mutations/profiles'

export default {
  data () {
    return {
      loading: false,
      profile: {
        pictureAttributes: {
          image: null
        }
      }
    }
  },

  props: {
    profileId: {
      type: String | Number,
      required: true
    }
  },

  methods: {
    edit () {
      this.loading = true
      this.$apollo.mutate({
        mutation: EditProfile,
        variables: {
          input: {
            id: this.profileId,
            attributes: {
              ...this.profile
            }
          }
        }
      }).then ( res => {
        this.loading = false
        this.$emit('close')
      })
    }
  }
}
</script>
