import gql from 'graphql-tag'

export const Follow = gql`
  mutation follow($input: FollowInput!) {
    follow(input: $input) {
      success
    }
  }
`

export const EditProfile = gql`
  mutation editProfile($input: EditProfileInput!) {
    editProfile(input: $input) {
      success
    }
  }
`
